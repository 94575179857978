

























































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import Empty from "@/components/empty.vue";
import {
  ZuzhijiagouList,
  AddComposeBumen,
  PostShowZhuanbingku,
} from "@/request/manage";
import { GetCurrentUserData } from "@/request/account";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Empty,
  },
})
export default class Name extends Vue {
  private user: any = {};
  private list: any[] = [];
  private ifShowAddSub: boolean = false;
  private tempSub: any = {};
  private filters: any = {
    search: "",
  };
  private ifShowEmpty: any = false;
  private get configuration() {
    return this.$store.state.configuration;
  }
  private getIfShow(item: any) {
    let d: any = {};
    this.user.role.forEach((ele: any) => {
      if (item["名称"] == ele["项目名称"]) {
        d = ele;
      }
    });
    if (d["名称"] == "项目负责人" || d["名称"] == "专病负责人") {
      return true;
    } else {
      return false;
    }
  }
  private switchChange(e: any, item: any, type: any) {
    const params = {
      project_id: item.project_id,
      show: e,
      type,
    };
    PostShowZhuanbingku(this, params).then((res: any) => {
      this.$store.commit("updateIsShowZhuanbingku", e);
    });
  }
  /**
   * @description 打开添加下级部门弹窗
   */
  private openAddSub(inti: any) {
    this.tempSub = {
      parent_institution_id: inti.institution_id,
      上级名称: inti["名称"],
    };
    this.ifShowAddSub = true;
  }
  /**
   * @description 打开编辑下级部门弹窗
   */
  private openComposeSub(inti: any) {
    this.tempSub = inti;
    this.ifShowAddSub = true;
  }
  /**
   * @description 新建或编辑下属部门
   */
  private saveSub() {
    if (!this.tempSub["名称"]) {
      this.$message.warning("部门名称必须填写");
      return;
    }
    const params = this.tempSub;
    AddComposeBumen(this, params).then((data: any) => {
      this.$message.success("保存成功");
      this.tempSub = {};
      this.ifShowAddSub = false;
      this.fetchList();
    });
  }
  /**
   * @description 获取列表数据
   */
  private fetchList() {
    const params: any = {
      params: {
        search: this.filters.search,
      },
    };
    ZuzhijiagouList(this, params).then((data: any) => {
      data.forEach((first: any) => {
        if (first["sub_institutions"]) {
          first["sub_institutions"].forEach((second: any) => {
            second["parent_institution_id"] = first["institution_id"];
            second["上级名称"] = first["名称"];
            if (second["sub_institutions"]) {
              second["sub_institutions"].forEach((third: any) => {
                third["parent_institution_id"] = second["institution_id"];
                third["上级名称"] = second["名称"];
              });
            }
          });
        }
      });
      this.list = data;
      if (this.list.length === 0) {
        this.ifShowEmpty = true;
      } else {
        this.ifShowEmpty = false;
      }
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    GetCurrentUserData(this).then((res: any) => {
      this.user = res;
      this.fetchList();
    });
  }
}
